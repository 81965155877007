<template>
  <div id="main">
    <img src="https://www.letras.com.br/img/logo-letras-original.png" class="img-fluid mb-5" width="300">
    <form id="content-box" @submit="submit">
      <div class="form-group">
        <label for="username"><b>Usuário</b></label>
        <input v-model="form.username" id="username" type="text" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="password"><b>Senha</b></label>
        <input ref="password" v-model="form.password" id="password" type="password" class="form-control" required />
      </div>
      <button class="btn btn-rounded btn-3 w-100">Acessar painel</button>
    </form>
  </div>
</template>

<script>
  export default {
    data: () => ({
      form: {
        username: '',
        password: ''
      }
    }),
    methods: {
      async submit(e) {
        e.preventDefault()
        try {
          await this.$store.dispatch('auth/login', this.form)
          this.$router.push({ path: '/' })
        } catch (err) {
          this.$toasted.global.error({ message : err })
          this.form.password = ''
          this.$refs.password.focus()
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "~@/styles/variables.scss";

  #main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #488db1;
    height: 100vh;
  }

  #content-box {
    padding: 60px 40px;
    width: 500px;
    background: rgba(#fff, .2);
    box-shadow: 0px 0px 25px rgba(#000, .1);
    border-radius: 10px;
  }

  .form-control {
    color: #fff;
    border: 2px solid #fff;
    background: transparent;
  }

  label {
    color: #fff;
  }
</style>